<template>
 <div>
  <b-card bg-variant="light">
    <b-form-group 
      label-cols-lg="3"
      label="Login"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Full Name:"
        label-for="nested-full-name"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-full-name"
          v-model="user_attr_copy.name"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Email Address:"
        label-for="nested-email-address"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-email-address"
          v-model="user_attr_copy.email"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Password:"
        label-for="nested-password"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-password"
          v-model="user_attr_copy.password"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Password Confirmation:"
        label-for="nested-password-confirmation"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-password-confirmation"
          v-model="user_attr_copy['password-confirmation']"
        ></b-form-input>
      </b-form-group>
    </b-form-group>

    <b-form-group
      label-cols-lg="3"
      label="Profile"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Nickname:"
        label-for="nested-nickname"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-nickname"
          v-model="profile_attr_copy.nickname"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Alias ID:"
        label-for="nested-alias-id"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-alias-id"
          v-model="profile_attr_copy['unique-alias']"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Mobile:"
        label-for="nested-mobile"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-mobile"
          v-model="profile_attr_copy.mobile"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Location:"
        label-for="nested-location"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-location"
          v-model="profile_attr_copy.location"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Will Work:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-model="profile_attr_copy['will-work']"
          name="will-work-check-button"
          switch
          :aria-describedby="ariaDescribedby"
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Will Consume:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-model="profile_attr_copy['will-consume']"
          name="will-consume-check-button"
          switch
          :aria-describedby="ariaDescribedby"
        >
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Will Audit:"
        label-cols-sm="3"
        label-align-sm="right"
        class="mb-0"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-checkbox
          v-model="profile_attr_copy['will-audit']"
          name="will-audit-check-button"
          switch
          :aria-describedby="ariaDescribedby"
        >
        </b-form-checkbox>
      </b-form-group>

    </b-form-group>
    <b-button block variant="primary" @click="callApi">Add User Profile</b-button>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import {amsMofifiedAttrs} from '@/utils'
import {amsSchema} from '@/schema'

export default {
  props: {
    reroute: {
      Type: Boolean,
      default: true
    },
    ams_template: {
      Type: Object
    }
  },
  data () {
    return {
      user_attr_copy: {},
      user_attr_orig: {},
      profile_attr_copy: {},
      profile_attr_orig: {}
    }
  },
  methods: {
    callApi () {
      http_v1.post (
        // 'signup', {
        'register', {
          user: {
            ...amsMofifiedAttrs(this.user_attr_copy, this.user_attr_orig)
          },
          profile: {
            ...amsMofifiedAttrs(this.profile_attr_copy, this.profile_attr_orig)
          },
          confirmed_url:  window.location.origin /* + this.$route.path */ + '/welcome'
        }
      ).then(response => {
        if( response.data) {
          this.$emit('takeAction')
          var message = "Check your inbox for a verification link to confirm your email address"
          this.$bvToast.toast(message, {
            title: 'Congratulations',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
        // if(this.reroute) {
        //   // this.$router.push( {name: 'ProfileIndex'})
        //   // this.$router.go(-1)
        // }
      }).catch(error => {
        window.console.log(error)
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
  mounted() {
    if(this.ams_template?.attributes) {
      this.attr_profile_copy = {...this.ams_template.attributes}
    }
    else {
      let included_obj = amsSchema.find( 
        el =>  
        el.type === 'users'
      )
      this.user_attr_copy = {...included_obj.attributes}
      included_obj = amsSchema.find(
        el =>
        el.type === 'profiles'
      )
      this.profile_attr_copy = {...included_obj.attributes}
    }
  }
}
</script>